<template>
  <b-row>
    <b-col cols="6">
      <b-row>
        <b-col cols="12">
          <b-card title="Informasi Produk">
            <!-- sku -->
            <b-form-group label="Nomor SKU (Kode Barang) :" label-size="sm">
              <b-form-input
                v-model="form.sku"
                size="sm"
                autocomplete="off"
                placeholder="SKU"
              />
            </b-form-group>
            <!-- nama produk -->
            <b-form-group label="Nama Produk :" label-size="sm">
              <b-form-input
                v-model="form.title"
                size="sm"
                autocomplete="off"
                placeholder="Nama Produk"
              />
            </b-form-group>
            <!-- stok dan harga dibuat col 6 -->
            <b-row>
              <b-col cols="6">
                <!-- stok -->
                <b-form-group label="Stok :" label-size="sm">
                  <cleave
                    v-model="form.stock"
                    :options="{
                      numeral: true,
                      numeralDecimalMark: ',',
                      numeralPositiveOnly: true,
                      delimiter: '.',
                    }"
                    class="form-control form-control-sm"
                    placeholder="Stok"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- harga -->
                <b-form-group label="Harga :" label-size="sm">
                  <cleave
                    v-model="form.price"
                    :options="{
                      numeral: true,
                      numeralDecimalMark: ',',
                      numeralPositiveOnly: true,
                      delimiter: '.',
                    }"
                    class="form-control form-control-sm"
                    placeholder="Harga"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- deskripsi -->
            <b-form-group label="Deskripsi Produk :" label-size="sm">
              <b-form-textarea
                v-model="form.description"
                size="sm"
                autocomplete="off"
                placeholder="Deskripsi Produk"
                rows="4"
                no-auto-shrink
                no-resize
              />
            </b-form-group>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card title="Dimensi dan Berat">
            <!-- berat -->
            <b-form-group label="Berat (KG) :" label-size="sm">
              <cleave
                v-model="form.weight"
                :options="{
                  numeral: true,
                  numeralDecimalMark: ',',
                  numeralPositiveOnly: true,
                  delimiter: '.',
                }"
                class="form-control form-control-sm"
                placeholder="Berat (KG)"
              />
            </b-form-group>
            <!-- panjang, lebar, tinggi dibuat col 4 -->
            <b-row>
              <b-col cols="4">
                <!-- panjang -->
                <b-form-group label="Panjang (CM) :" label-size="sm">
                  <cleave
                    v-model="form.length"
                    :options="{
                      numeral: true,
                      numeralDecimalMark: ',',
                      numeralPositiveOnly: true,
                      delimiter: '.',
                    }"
                    class="form-control form-control-sm"
                    placeholder="Panjang (CM)"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <!-- lebar -->
                <b-form-group label="Lebar (CM) :" label-size="sm">
                  <cleave
                    v-model="form.width"
                    :options="{
                      numeral: true,
                      numeralDecimalMark: ',',
                      numeralPositiveOnly: true,
                      delimiter: '.',
                    }"
                    class="form-control form-control-sm"
                    placeholder="Lebar (CM)"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <!-- tinggi -->
                <b-form-group label="Tinggi (CM) :" label-size="sm">
                  <cleave
                    v-model="form.height"
                    :options="{
                      numeral: true,
                      numeralDecimalMark: ',',
                      numeralPositiveOnly: true,
                      delimiter: '.',
                    }"
                    class="form-control form-control-sm"
                    placeholder="Tinggi (CM)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="6">
      <b-row>
        <b-col cols="12">
          <b-card title="Foto Produk">
            <b-form-group label="Foto Produk :" label-size="sm">
              <b-form-file
                v-model="selectImages"
                :disabled="
                  tableImages.rows.length +
                    (form.existingImages && form.existingImages.length
                      ? form.existingImages.length
                      : 0) >=
                  5
                "
                size="sm"
                placeholder="Pilih Foto"
                accept="image/*"
                multiple
                @input="previewImages"
              />
            </b-form-group>
            <b-table-simple ref="table" responsive="sm" bordered striped>
              <b-tbody>
                <b-tr v-for="(row, index) in tableImages.rows" :key="row.key">
                  <b-td>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <b-img
                        :src="row.image"
                        fluid
                        thumbnail
                        alt="image"
                        style="
                          object-fit: cover;
                          object-position: center;
                          width: 74px;
                          height: 74px;
                        "
                        width="84"
                        height="84"
                      />
                      <span class="ml-1">{{ row.sizeHuman }}</span>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        class="ml-1"
                        @click="deleteImage(index)"
                      >
                        <Feather-icon icon="Trash2Icon" /> Hapus Foto
                      </b-button>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr v-for="(row, index) in form.existingImages" :key="index">
                  <b-td>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <b-img
                        :src="row"
                        fluid
                        thumbnail
                        alt="image"
                        style="
                          object-fit: cover;
                          object-position: center;
                          width: 74px;
                          height: 74px;
                        "
                      />
                      <span>-</span>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        class="ml-1"
                        @click="deleteExistingImage(index)"
                      >
                        <Feather-icon icon="Trash2Icon" /> Hapus Foto
                      </b-button>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <small>
              {{
                tableImages.rows.length +
                (form.existingImages && form.existingImages.length
                  ? form.existingImages.length
                  : 0)
              }}
              Foto Produk</small
            >
            <!-- submit button dan reset button -->
            <div class="d-flex justify-content-around mt-2">
              <b-button variant="primary" size="sm" @click="submitData">
                <Feather-icon icon="SaveIcon" size="16" /> Simpan Produk
              </b-button>
              <b-button
                variant="warning"
                size="sm"
                :disabled="$route.params.id"
                @click="resetForm"
              >
                <Feather-icon icon="XIcon" size="16" /> Reset Formulir
              </b-button>
              <!--back button-->
              <b-button
                variant="danger"
                size="sm"
                :to="{ name: 'productsMaster' }"
              >
                <Feather-icon icon="ChevronLeftIcon" size="16" /> Kembali
              </b-button>
            </div>
          </b-card>
        </b-col>
        <b-col v-if="debugmode" cols="12">
          <b-card title="Debug Mode">
            <pre>{{ form }}</pre>
            <pre>{{ tableImages }}</pre>

            <b-button
              variant="secondary"
              size="sm"
              block
              @click="fillFormWithFakeData"
            >
              <Feather-icon icon="RefreshCwIcon" size="16" /> Fake Data
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BImg,
  BButton,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import { faker } from '@faker-js/faker';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import axios from '@axios';
import Cleave from 'vue-cleave-component';

export default defineComponent({
  name: '',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BImg,
    BButton,
    FeatherIcon,
    Cleave,
  },
  data() {
    return {
      form: {
        sku: '',
        title: '',
        stock: 0,
        price: 0,
        description: '',
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
      },
      tableImages: {
        rows: [],
      },
      selectImages: [],
      debugmode: false,
    };
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const debugMode = urlParams.has('debugmode');

    const urlIsAdd = window.location.pathname.includes('add');
    const urlIsEdit = window.location.pathname.includes('edit');

    if (debugMode && urlIsAdd) {
      this.fillFormWithFakeData();
      this.debugmode = true;
    }

    if (debugMode && urlIsEdit) {
      this.debugmode = true;
    }

    if (this.$route.params.id) {
      await this.fetchData();
    }
  },
  // make sure images has only 5 items
  watch: {
    'tableImages.rows': {
      handler() {
        if (
          this.tableImages.rows.length +
            (this.form.existingImages && this.form.existingImages.length
              ? this.form.existingImages.length
              : 0) >
          5
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Galat',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Maksimal 5 Foto Produk',
            },
          });
          this.tableImages.rows.pop();
        }
      },
      deep: true,
    },
  },
  methods: {
    fillFormWithFakeData() {
      // Fill form fields with fake data
      this.form.sku = faker.string.uuid();
      this.form.title = faker.commerce.productName();
      this.form.stock = faker.number.int({ min: 1, max: 100 });
      this.form.price = faker.commerce.price({
        min: 10000,
        max: 1000000,
        dec: 0,
      });
      this.form.description = faker.lorem.paragraph();
      this.form.weight = faker.number.int({ min: 0.1, max: 10 });
      this.form.length = faker.number.int({ min: 10, max: 100 });
      this.form.width = faker.number.int({ min: 10, max: 100 });
      this.form.height = faker.number.int({ min: 10, max: 100 });
    },

    async fetchData() {
      // Fetch data from server
      const response = await axios.get(`/product/${this.$route.params.id}`);

      // assign response data to form
      if (response && response.data && response.data.status === true) {
        this.form.title = response.data.data.title;
        this.form.sku = response.data.data.sku;
        this.form.stock = response.data.data.stock;
        this.form.price = response.data.data.price;
        this.form.description = response.data.data.description;
        this.form.weight = response.data.data.weight;
        this.form.length = response.data.data.length;
        this.form.width = response.data.data.width;
        this.form.height = response.data.data.height;

        // assign existing images to form
        this.form.existingImages = response.data.data.existingImages;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Galat',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Terjadi Kesalahan Saat Mengambil Data Produk',
          },
        });

        // delay 1s and redirect to product master
        setTimeout(() => {
          this.$router.push({ name: 'productsMaster' });
        }, 1000);
      }
    },

    previewImages() {
      // check if existing images is more than 5
      if (
        this.tableImages.rows.length +
          (this.form.existingImages && this.form.existingImages.length
            ? this.form.existingImages.length
            : 0) >=
        5
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Galat',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Maksimal 5 Foto Produk',
          },
        });

        this.selectImages = [];

        return;
      }

      const files = this.selectImages; // Get the files from the input

      const sizeHumanReadable = size => {
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return `${(size / 1024 ** i).toFixed(2) * 1} ${
          ['B', 'kB', 'MB', 'GB', 'TB'][i]
        }`;
      };
      // loop through the files
      for (let i = 0; i < files.length; i += 1) {
        // if image size is more than 2MB
        if (Number(files[i].size) <= 2000000) {
          const reader = new FileReader(); // Create a new instance of FileReader for each file
          const randomKey = Math.random().toString(36).substring(7);

          reader.onload = e => {
            this.tableImages.rows.push({
              image: e.target.result,
              file: files[i],
              sizeHuman: sizeHumanReadable(files[i].size),
              name: files[i].name,
              key: `${files[i].name}-${files[i].size}-${randomKey}`,
            });

            this.$nextTick(() => {
              this.$forceUpdate();
            });
          };

          reader.readAsDataURL(files[i]);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Galat',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Foto ${files[i].name} melebihi batas ukuran 2MB`,
            },
          });
        }
      }

      // clear the input
      this.selectImages = [];
    },

    deleteImage(index) {
      this.tableImages.rows.splice(index, 1);
    },

    deleteExistingImage(index) {
      this.form.existingImages.splice(index, 1);

      // refresh the layout
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    resetForm() {
      this.form = {
        sku: '',
        title: '',
        stock: 0,
        price: 0,
        description: '',
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
      };
      this.tableImages.rows = [];
    },

    async submitData() {
      // check all form fields are filled
      const formFields = Object.values(this.form);

      // make sure image is uploaded
      if (this.tableImages.rows.length === 0 && !this.form.existingImages) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Galat',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Foto Produk Harus Diunggah',
          },
        });

        return;
      }

      if (formFields.includes('') || formFields.includes(0)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Galat',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Semua kolom formulir harus diisi',
          },
        });

        return;
      }

      // create form data
      const formData = new FormData();

      // append form data dynamically
      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key]);
      });

      // append images
      if (this.tableImages && this.tableImages.rows.length > 0) {
        console.log('tableImages', this.tableImages);
        this.tableImages.rows.forEach(image => {
          formData.append('images', image.file);
        });
      }

      // send form data to server
      const response = await axios[this.$route.params.id ? 'put' : 'post'](
        `/product/${this.$route.params.id || ''}`,
        formData,
      );

      if (response && response.data && response.data.status === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sukses',
            icon: 'CheckIcon',
            variant: 'success',
            text: `${
              this.$route.params.id ? 'Memperbarui' : 'Menambahkan'
            } Data Produk Berhasil`,
          },
        });

        return;
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Galat',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: 'Terjadi Kesalahan Saat Menyimpan Data Produk',
        },
      });
    },
  },
});
</script>

<style lang="scss">
// create square image and fill the rest with white space
.sq-image {
  object-fit: cover;
  object-position: center;
}
</style>
